import React from 'react'
const InstaIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
    >
      <rect
        x="3.5"
        y="3.75"
        width="14"
        height="14"
        rx="4"
        stroke="#3C3E49"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="10.5"
        cy="10.75"
        r="2.625"
        stroke="#3C3E49"
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.4375 6.81255V6.81343"
        stroke="#3C3E49"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default InstaIcon
